import React from 'react';
import classNames from 'classnames/bind';
import {
  Box,
  Button,
  CloudinaryImage,
  Modal,
  RichText,
  Text,
  useScreenSize,
} from '@stitch-fix/mode-react';
import styles from './style.module.scss';
import { useGetAddProfileIngressModalQuery } from './getAddProfileModal';

const cx = classNames.bind(styles);

export interface AddProfileModalProps {
  isModalOpen: boolean;
  onClose: () => void;
}

const AddProfileModal = ({ isModalOpen, onClose }: AddProfileModalProps) => {
  const isMobileScreenSize = useScreenSize() === 'sm';

  const { data, loading, error } = useGetAddProfileIngressModalQuery({
    skip: !isModalOpen,
  });

  const modalData = data?.client?.addProfileIngressModal;

  const renderErrorState = () => {
    return (
      <Box my={2}>
        <Text color="blue-45" setting="body-medium" mb={0.5}>
          Oops! Something went wrong.
        </Text>
        <Text color="blue-45" setting="body-medium">
          Please try again.
        </Text>
      </Box>
    );
  };

  const renderModalContents = () => {
    if (loading) {
      return null;
    }

    if (error) {
      return renderErrorState();
    }

    if (!modalData) {
      return null;
    }

    return (
      <Box>
        <Box maxWidth={{ sm: '280px', md: '300px', lg: '380px' }} mx="auto">
          <Text color="blue-45" setting="display-large" mb={1.5} mt={0.75}>
            <RichText>{String(modalData?.title?.formatted)}</RichText>
          </Text>
        </Box>
        <Box bgColor="gray-99" p={1}>
          <ul className={cx('list')}>
            {modalData?.body.map(item => (
              <li key={item.substring(0, 15).replace(/\s+/g, '-')}>
                <Text color="blue-45" setting="body-medium" mb={0.5}>
                  {item}
                </Text>
              </li>
            ))}
          </ul>
        </Box>
        <Button
          as="a"
          variant="filled-statement"
          mt={1.5}
          onClick={onClose}
          href={modalData?.buttonCta?.url}
          width={isMobileScreenSize ? 'fill' : 'fixed'}
        >
          {modalData?.buttonCta?.label?.text}
        </Button>
      </Box>
    );
  };

  const renderModalImage = () => {
    if (loading || error || !modalData) return null;

    return (
      <Box className={cx('image')}>
        <CloudinaryImage
          baseUrl="https://d3n78nkjl8tizo.cloudfront.net/stitch-fix/image/upload/"
          mediaPath={modalData?.media?.mediaPath}
          sources={{
            sm: {
              w: 560,
              h: 560,
            },
          }}
          alt={modalData?.media?.altText}
        />
      </Box>
    );
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      hero={renderModalImage()}
      variant="slide-up-small"
      hAlign="center"
      bodyLock="never"
    >
      {renderModalContents()}
    </Modal>
  );
};

export default AddProfileModal;
