import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import slashFree from 'remove-trailing-slash';
import { G } from '@mobily/ts-belt';
import getMobileConfig from '../../helpers/mobile';
import { registerNavData } from '../../services/navDataChangeHandler';
import type { Client, Visitor } from './types';
import { configParamInputs } from '../../helpers/configParams';
import { getDocument, getWindow } from '../../helpers/getBrowserGlobals';
import { getGraphQLApiProvider } from './GraphQLApiProvider';
import {
  useGetNavDataQuery,
  GetNavDataQuery,
  GetNavDataDocument,
} from './getNavData';
import useCachedQuery from './useCachedQuery';
import {
  type GraphqlBanner,
  isSupportedBannerType,
} from '../Header/Banners/BannerTypes';

interface NavContext {
  client: Client | null;
  rootUrl: string;
  currentPath?: string;
  banners: GraphqlBanner[];
  visitor: Visitor | null;
  dataLoaded: boolean;
}

const NavContext = createContext<NavContext>({
  client: null,
  rootUrl: '',
  banners: [],
  visitor: null,
  dataLoaded: false,
});
const useNavContext = () => useContext(NavContext);

export const LOCAL_STORAGE_CACHE_KEY = 'knit:getNavDataQueryCache';

interface NavContextWrapperProps {
  children: ReactNode;
  rootUrl: string;
  mobileConfigKey?: 'showHeader' | 'showFooter';
  /**
   * When `true`, this component opts out of the behavior where child components are hidden until
   * nav data is available. This is intended for test environments, to make tests less asynchronous
   * (see `helpers/test-utils.tsx`).
   */
  skipHideBeforeLoaded?: boolean;
}

/**
 * This component acts as the central repository for state that can be used across the
 * header, footer, and all children.
 */
const NavContextWrapper = ({
  children,
  rootUrl,
  mobileConfigKey,
  skipHideBeforeLoaded,
}: NavContextWrapperProps) => {
  const [currentPath, setCurrentPath] = useState('');
  const shouldRender = Boolean(
    !mobileConfigKey || getMobileConfig()[mobileConfigKey],
  );

  const {
    refetch,
    data: refreshedNavData,
    loading: navDataLoading,
  } = useGetNavDataQuery({
    // Avoid caching in test - otherwise if we try to render knit with different mock data
    // in the same test file, we'll always get the data cached from the first test run.
    // https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
    fetchPolicy: process.env.NODE_ENV === 'test' ? 'no-cache' : undefined,
    skip: !shouldRender,
    variables: {
      configParams: configParamInputs,
      exposureDetails: {
        referrer: getDocument()?.referrer || '',
        uri: getWindow()?.location.href || '',
      },
    },
  });

  const gqlNavData = useCachedQuery<GetNavDataQuery>(
    LOCAL_STORAGE_CACHE_KEY,
    refreshedNavData,
    GetNavDataDocument,
  );

  useEffect(() => {
    const w = getWindow();

    if (G.isObject(w)) {
      setCurrentPath(slashFree(w.location.pathname));
    }
  }, []);

  useEffect(() => {
    const w = getWindow();

    if (G.isObject(w) && G.isUndefined(w.stitchfix)) w.stitchfix = {};

    if (G.isObject(w) && G.isObject(w.stitchfix)) {
      w.stitchfix.refreshNavData = () => {
        refetch().then(({ data }) => {
          registerNavData(data, { updateSelf: true });
        });
      };
    }
  }, [refetch]);

  const isVisible =
    skipHideBeforeLoaded || Boolean(gqlNavData) || !navDataLoading;

  const contextValue = {
    banners: (gqlNavData?.banners || []).filter(isSupportedBannerType),
    client: gqlNavData?.client || null,
    currentPath,
    rootUrl,
    visitor: gqlNavData?.visitor || null,
    dataLoaded: isVisible,
  };

  return (
    <NavContext.Provider value={contextValue}>
      {/**
       * this is a quick and dirty solution to address SSR/static generation
       * in Next.js and Dynamic Shock. it helps us avoid quick flashes of
       * incorrect state (logged out vs logged in).
       *
       * A more long term solution to be addressed in:
       * https://stitchfix.atlassian.net/browse/CSE-253
       */}
      {shouldRender && (
        <div
          style={{
            visibility: isVisible ? 'visible' : 'hidden',
          }}
        >
          {children}
        </div>
      )}
    </NavContext.Provider>
  );
};

const withGraphqlProvider = <T extends {}>(
  Component: React.ComponentType<T>,
) => {
  return (props: T) => {
    const GraphQLApiProvider = getGraphQLApiProvider();

    return (
      <GraphQLApiProvider>
        <Component {...props} />
      </GraphQLApiProvider>
    );
  };
};

export default withGraphqlProvider(NavContextWrapper);
export { NavContext, useNavContext };
