import React, { useContext, useState } from 'react';
import classNames from 'classnames/bind';
import { IconAddInCircle32, StickerLabel, Text } from '@stitch-fix/mode-react';
import { NavContext } from '../../../../../NavContextWrapper';
import changeClient from '../../../../../../api/changeClient';
import { KnitLink } from '../../../../../KnitLink';
import { Client } from '../../../../../NavContextWrapper/types';
import styles from './style.module.scss';
import AccountBadge from '../../AccountBadge';
import { useHousehold } from '../../../helpers/useHousehold';
import { isInFeatureFlag } from '../../../../../../helpers/isInFeatureFlag';
import AddProfileModal from './AddProfileModal';

interface HouseholdMembersProps {
  client: Client;
}

const cx = classNames.bind(styles);

const HouseholdMembers = ({ client }: HouseholdMembersProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { rootUrl } = useContext(NavContext);
  const { getCircleColorForUser, householdMembers } = useHousehold(client);

  const householdMembersBesidesSelf = householdMembers.filter(
    member => member.id !== client.id,
  );

  const isInBuyingForOthersFF = isInFeatureFlag(
    'household_profiles_buying_for_others',
    client?.featureFlags,
  );

  const handleChangeClient = (clientId: string) => {
    changeClient(rootUrl, clientId).then(responseText => {
      if (responseText.auth_token) {
        // eslint-disable-next-line no-restricted-globals -- inside useEffect
        window.location.assign(`${rootUrl}/client`);
      } else {
        throw Error(responseText.error);
      }
    });
  };

  const handleProfileModalOpen = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (!isInBuyingForOthersFF) {
      return;
    }
    e.preventDefault();
    setIsModalOpen(true);
  };

  return (
    <>
      <Text setting="eyebrow-small" ml={0.375} my={0.5}>
        Switch Profiles
      </Text>
      {householdMembersBesidesSelf.map(member => {
        return (
          <button
            key={member.id}
            data-ga="meganav-primary-dropdown-switch-account"
            className={cx('section-row', 'section-row-button')}
            onClick={() => handleChangeClient(member.id)}
            type="button"
          >
            <div className={styles['section-row-icon']}>
              <AccountBadge
                circleColor={getCircleColorForUser(member)}
                text={member.firstName.charAt(0)}
              />
            </div>
            <Text height="standard" family="regular" scale="3">
              {member.firstName}
            </Text>
          </button>
        );
      })}
      <KnitLink
        className={cx('section-row', 'section-row-link')}
        data-ga={
          isInBuyingForOthersFF
            ? 'meganav-primary-dropdown-add-profile'
            : 'meganav-primary-dropdown-add-kid'
        }
        onClick={handleProfileModalOpen}
        to={isInBuyingForOthersFF ? '#' : '/client/kids'}
      >
        <div className={styles['section-row-icon']}>
          <IconAddInCircle32 purpose="decorative" />
        </div>
        {isInBuyingForOthersFF ? (
          <>
            Add a profile{' '}
            <StickerLabel ml={0.5} variant="citrus">
              New
            </StickerLabel>
          </>
        ) : (
          "Add a Kid's Profile"
        )}
      </KnitLink>
      <AddProfileModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default HouseholdMembers;
