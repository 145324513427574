import type { PartialDeep } from 'type-fest';
import merge from 'ts-deepmerge';
import { GetNavDataQuery } from './components/NavContextWrapper/getNavData';
import type { Client } from './components/NavContextWrapper/types';

type LoggedInGetNavDataQuery = GetNavDataQuery & { client: Client };

const loggedOut: GetNavDataQuery = {
  client: null,
  banners: [],
  visitor: {
    countryCode: 'US',
    id: '76ee0603-bc29-4da8-9559-ba164870df63',
  },
};

const loggedOutWithSitewideBanner: GetNavDataQuery = {
  client: null,
  banners: [
    {
      __typename: 'ModeBannerSitewide',
      id: 'uk-discount-promo-2',
      headline: 'Complete your checkout',
      message: 'Save 50% when you purchase all five items!',
      icon: 'INFORMATION',
      sitewideVariant: 'PROMOTIONAL',
      cta: {
        label: {
          key: 'schedule-a-fix',
          text: 'Schedule a Fix!',
        },
        url: '/fix-home',
      },
      secondaryCta: {
        label: {
          key: 'go-home',
          text: 'Go home!',
        },
        url: '/home',
      },
    },
  ],
  visitor: {
    countryCode: 'US',
    id: '76ee0603-bc29-4da8-9559-ba164870df63',
  },
};

const loggedOutWithSlimBanner: GetNavDataQuery = {
  client: null,
  banners: [
    {
      __typename: 'ModeBannerSlim',
      id: 'eligible-discount-promo-2',
      message: 'Save 20% on all Freestyle purchases!',
      icon: 'ERROR',
      slimVariant: 'INVERSE',
      cta: {
        label: {
          key: 'go-now',
          text: 'Go now!',
        },
        url: '/categories',
      },
    },
  ],
  visitor: {
    countryCode: 'US',
    id: '76ee0603-bc29-4da8-9559-ba164870df63',
  },
};

const loggedIn: LoggedInGetNavDataQuery = {
  banners: [],
  client: {
    id: '8377233',
    businessLine: 'WOMENS',
    region: 'US',
    firstName: 'Morris',
    capabilities: {
      canShop: true,
      canScheduleFix: true,
      needsOnboarding: false,
    },
    configParams: [
      {
        name: 'eng.kufak.incentives.loyalty_pilot',
        value: 'spend_400_get_25',
      },
    ],
    featureFlags: [],
    referrals: {
      incentive: {
        senderCredit: {
          value: 3000,
          currency: 'USD',
        },
      },
    },
    shoppingBag: {
      count: 7,
    },
    household: {
      members: [
        {
          id: '8377233',
          businessLine: 'WOMENS',
          firstName: 'Morris',
        },
        {
          id: '12345',
          businessLine: 'KIDS',
          firstName: 'Oscar',
        },
        {
          id: '23456',
          businessLine: 'KIDS',
          firstName: 'Skimbleshanks',
        },
        {
          id: '34567',
          businessLine: 'KIDS',
          firstName: 'Gus',
        },
        {
          id: '45678',
          businessLine: 'KIDS',
          firstName: 'Nermal',
        },
        {
          id: '56789',
          businessLine: 'KIDS',
          firstName: 'Grizabella',
        },
      ],
    },
    stylefileStatus: 'COMPLETE',
  },
  visitor: {
    countryCode: 'US',
    id: '76ee0603-bc29-4da8-9559-ba164870df63',
  },
};

export const loggedInFactory = (
  overrides: PartialDeep<LoggedInGetNavDataQuery>,
): LoggedInGetNavDataQuery =>
  merge.withOptions({ mergeArrays: false }, loggedIn, overrides);

const loggedInZeroBagCount = loggedInFactory({
  client: {
    shoppingBag: {
      count: 0,
    },
  },
});

const loggedInNoShop = loggedInFactory({
  client: {
    capabilities: {
      canShop: false,
    },
  },
});

export const loggedInWithSlimBanner = loggedInFactory({
  banners: [
    {
      __typename: 'ModeBannerSlim',
      id: 'eligible-discount-promo-2',
      message: 'Save 20% on all Freestyle purchases!',
      icon: 'ERROR',
      slimVariant: 'INVERSE',
      cta: {
        label: {
          key: 'go-now',
          text: 'Go now!',
        },
        url: '/categories',
      },
    },
  ],
});

const loggedInHasNotCompletedOnboarding = loggedInFactory({
  client: {
    capabilities: {
      canShop: true,
      needsOnboarding: true,
    },
    household: {
      members: [
        {
          id: '8377233',
          businessLine: 'WOMENS',
          firstName: 'Morris',
        },
        {
          id: '12345',
          businessLine: 'KIDS',
          firstName: 'Oscar',
        },
        {
          id: '8377238',
          businessLine: 'KIDS',
          firstName: 'Grizabella',
        },
      ],
    },
  },
});

const loggedInHasNotCompletedOnboardingInBuyingForOthersFF = loggedInFactory({
  client: {
    capabilities: {
      canShop: true,
      needsOnboarding: true,
    },
    featureFlags: [
      {
        enabled: true,
        name: 'household_profiles_buying_for_others',
      },
    ],
  },
});

const loggedInHasNotCompletedOnboardingZeroBagCount = loggedInFactory({
  client: {
    capabilities: {
      canShop: true,
      needsOnboarding: true,
    },
    household: {
      members: [],
    },
    shoppingBag: {
      count: 0,
    },
  },
});

const loggedInEdgeCases = loggedInFactory({
  client: {
    businessLine: 'KIDS',
    firstName: '&Jeffery',
    household: {
      members: [
        {
          id: '8377236',
          businessLine: 'MENS',
          // Spaces surrounding name
          firstName: ' Chippo ',
        },
        {
          id: '8377237',
          businessLine: 'KIDS',
          // Diacritic first character
          firstName: 'Ólafur',
        },
        {
          id: '8377238',
          businessLine: 'KIDS',
          // Long name
          firstName: 'Jacqueline-Ashbury',
        },
        {
          id: '8377239',
          businessLine: 'KIDS',
          // Non-letter first character
          firstName: '&Jeffery',
        },
        {
          id: '8377240',
          businessLine: 'KIDS',
          // Number first character
          firstName: '7 of 9',
        },
      ],
    },
    referrals: {
      incentive: {
        senderCredit: null,
      },
    },
  },
});

const loggedInKidsAccount = loggedInFactory({
  client: {
    businessLine: 'KIDS',
    firstName: 'Oscar',
    id: '12345',
    shoppingBag: {
      count: 0,
    },
  },
});

// TODO remove after kids categories feature
const loggedInKidsAccountKidsCategories = loggedInFactory({
  client: {
    businessLine: 'KIDS',
    firstName: 'Oscar',
    id: '12345',
    shoppingBag: {
      count: 0,
    },
    featureFlags: [
      {
        enabled: true,
        name: 'client.global.kids_categories',
      },
    ],
  },
});

const loggedInShellAccount = loggedInFactory({
  client: {
    businessLine: 'SHELL',
    household: {
      members: [
        {
          id: '8377233',
          businessLine: 'SHELL',
          firstName: 'Morris',
        },
        {
          id: '12345',
          businessLine: 'KIDS',
          firstName: 'Oscar',
        },
      ],
    },
  },
});

const loggedInStyleFileIneligible = loggedInFactory({
  client: {
    stylefileStatus: 'INELIGIBLE',
  },
});

const loggedInBuyingForOthersFF = loggedInFactory({
  client: {
    featureFlags: [
      {
        enabled: true,
        name: 'household_profiles_buying_for_others',
      },
    ],
  },
});

/**
 * A collection of mock responses for knit's navigation graphql query.
 */
export const gqlMocks = {
  loggedOut,
  loggedOutWithSitewideBanner,
  loggedOutWithSlimBanner,
  loggedIn,
  loggedInZeroBagCount,
  loggedInNoShop,
  loggedInWithSlimBanner,
  loggedInHasNotCompletedOnboarding,
  loggedInHasNotCompletedOnboardingInBuyingForOthersFF,
  loggedInHasNotCompletedOnboardingZeroBagCount,
  loggedInEdgeCases,
  loggedInKidsAccount,
  loggedInShellAccount,
  loggedInKidsAccountKidsCategories,
  loggedInStyleFileIneligible,
  loggedInBuyingForOthersFF,
} as const;
