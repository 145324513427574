import React, { useMemo, type ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';

export type RichTextProps = {
  children: string;
};

// react-string-replace is a much lighter-weight alternative to full markdown packages; since
// we only support a very limited subset of markdown, this is a good fit for our use case
// See documentation: https://github.com/iansinnott/react-string-replace?tab=readme-ov-file#multiple-replacements-on-a-single-string

// We use our own incrementing counter to generate unique keys for each formatted element.
// See https://github.com/iansinnott/react-string-replace/issues/18 for why we aren't using their
// returned `i` value instead. Because we `useMemo()`, we are not too concerned about key stability.
let keyCounter = 0;

const getKey = () => {
  keyCounter += 1;

  return keyCounter;
};

const formatBold = (text: string | ReactNode[]) => {
  return reactStringReplace(text, /\*(.*?)\*/g, match => (
    // format() is called on the inner match to handle nested formatting, e.g. _*Bold and italic*_
    <strong key={getKey()}>{format(match)}</strong>
  ));
};

const formatItalic = (text: string | ReactNode[]) => {
  return reactStringReplace(text, /_(.*?)_/g, match => (
    <em key={getKey()}>{format(match)}</em>
  ));
};

const formatNewlines = (text: string | ReactNode[]) => {
  return reactStringReplace(text, '\n', () => <br key={getKey()} />);
};

const format = (text: string | ReactNode[]) =>
  formatBold(formatItalic(formatNewlines(text)));

/**
 * Parses markdown-like syntax to style text. Supports `*bold*` and `_italic_` text. This is
 * typically used with content from an API, such as from our `RichText.formatted` [graphql
 * type](https://studio.apollographql.com/graph/sfix-kufak-eng/variant/production/schema/reference/objects/RichText).
 */
const RichText = ({ children }: RichTextProps) => {
  const formatted = useMemo(() => format(children), [children]);

  return <>{formatted}</>;
};

export default RichText;
