/* THIS IS FILE IS AUTO-GENERATED BY graphql-codegen. DO NOT EDIT THIS FILE DIRECTLY! */
/* eslint-disable @typescript-eslint/no-unused-vars, no-restricted-syntax */

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as Types from '../../types/definitions/graphql-schema.d';

const defaultOptions = {} as const;

export type GetNavDataQueryVariables = Types.Exact<{
  configParams: Types.ConfigParamInput[] | Types.ConfigParamInput;
  exposureDetails: Types.ConfigParamExposureDetailsInput;
}>;

export type GetNavDataQuery = {
  __typename?: 'Query';
  banners: (
    | { __typename: 'ModeBannerOnPage' }
    | {
        __typename: 'ModeBannerSitewide';
        headline?: string | null;
        icon?: Types.ModeIcon | null;
        id: string;
        message: string;
        sitewideVariant: Types.ModeBannerSitewideVariant;
        cta?: {
          __typename?: 'CallToAction';
          url: string;
          label: { __typename?: 'I18nCopy'; key: string; text: string };
        } | null;
        secondaryCta?: {
          __typename?: 'CallToAction';
          url: string;
          label: { __typename?: 'I18nCopy'; key: string; text: string };
        } | null;
      }
    | {
        __typename: 'ModeBannerSlim';
        icon?: Types.ModeIcon | null;
        id: string;
        message: string;
        slimVariant: Types.ModeBannerSlimVariant;
        cta?: {
          __typename?: 'CallToAction';
          url: string;
          label: { __typename?: 'I18nCopy'; key: string; text: string };
        } | null;
      }
  )[];
  client?: {
    __typename?: 'Client';
    id: string;
    businessLine: Types.BusinessLine;
    region: Types.Region;
    firstName: string;
    stylefileStatus: Types.ClientStylefileStatus;
    capabilities: {
      __typename?: 'ClientCapabilities';
      canShop: boolean;
      canScheduleFix: boolean;
      needsOnboarding: boolean;
    };
    configParams: {
      __typename?: 'ConfigParam';
      name: string;
      value: string;
    }[];
    featureFlags: {
      __typename?: 'FeatureFlag';
      name: string;
      enabled: boolean;
    }[];
    referrals: {
      __typename?: 'Referrals';
      incentive?: {
        __typename?: 'ReferralIncentive';
        senderCredit?: {
          __typename?: 'Money';
          value: number;
          currency: Types.Currency;
        } | null;
      } | null;
    };
    shoppingBag?: { __typename?: 'ShoppingBag'; count?: number | null } | null;
    household?: {
      __typename?: 'Household';
      members: {
        __typename?: 'HouseholdMember';
        id: string;
        businessLine: Types.BusinessLine;
        firstName: string;
      }[];
    } | null;
  } | null;
  visitor: { __typename?: 'Visitor'; countryCode?: string | null; id: string };
};

export const GetNavDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getNavData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'configParams' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ConfigParamInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'exposureDetails' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConfigParamExposureDetailsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'banners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ModeBannerSitewide' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headline' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondaryCta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'sitewideVariant' },
                        name: { kind: 'Name', value: 'variant' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ModeBannerSlim' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'slimVariant' },
                        name: { kind: 'Name', value: 'variant' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLine' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'capabilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canShop' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canScheduleFix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsOnboarding' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configParams' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'params' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'configParams' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'offlineLookupOnly' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'exposureDetails' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'exposureDetails' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureFlags' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'name' },
                                value: {
                                  kind: 'StringValue',
                                  value: 'client.global.kids_categories',
                                  block: false,
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'name' },
                                value: {
                                  kind: 'StringValue',
                                  value: 'household_profiles_buying_for_others',
                                  block: false,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referrals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'incentive' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'supportedIncentives',
                            },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'SINGLE_SIDED' },
                                { kind: 'EnumValue', value: 'DUAL_SIDED' },
                                { kind: 'EnumValue', value: 'PROMO_BONUS' },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'senderCredit' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currency' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shoppingBag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'household' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'businessLine' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stylefileStatus' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visitor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetNavDataQuery__
 *
 * To run a query within a React component, call `useGetNavDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavDataQuery({
 *   variables: {
 *      configParams: // value for 'configParams'
 *      exposureDetails: // value for 'exposureDetails'
 *   },
 * });
 */
export function useGetNavDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNavDataQuery,
    GetNavDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetNavDataQuery, GetNavDataQueryVariables>(
    GetNavDataDocument,
    options,
  );
}
export function useGetNavDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNavDataQuery,
    GetNavDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetNavDataQuery, GetNavDataQueryVariables>(
    GetNavDataDocument,
    options,
  );
}
export type GetNavDataQueryHookResult = ReturnType<typeof useGetNavDataQuery>;
export type GetNavDataLazyQueryHookResult = ReturnType<
  typeof useGetNavDataLazyQuery
>;
export type GetNavDataQueryResult = Apollo.QueryResult<
  GetNavDataQuery,
  GetNavDataQueryVariables
>;
