/* THIS IS FILE IS AUTO-GENERATED BY graphql-codegen. DO NOT EDIT THIS FILE DIRECTLY! */
/* eslint-disable @typescript-eslint/no-unused-vars, no-restricted-syntax */

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as Types from '../../../../../../../types/definitions/graphql-schema.d';

const defaultOptions = {} as const;

export type GetAddProfileIngressModalQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAddProfileIngressModalQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    addProfileIngressModal: {
      __typename?: 'AddProfileIngressModal';
      body: string[];
      media: {
        __typename?: 'Image';
        baseUrl: string;
        mediaPath: string;
        altText: string;
        height?: number | null;
        width?: number | null;
      };
      title: { __typename?: 'RichText'; formatted: string; plain: string };
      buttonCta: {
        __typename?: 'CallToAction';
        actionType: Types.CallToActionTypes;
        url: string;
        label: { __typename?: 'I18nCopy'; key: string; text: string };
      };
    };
  } | null;
};

export const GetAddProfileIngressModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAddProfileIngressModal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addProfileIngressModal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'baseUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaPath' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'altText' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'height' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'width' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'title' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'formatted' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'plain' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'buttonCta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'actionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAddProfileIngressModalQuery__
 *
 * To run a query within a React component, call `useGetAddProfileIngressModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddProfileIngressModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddProfileIngressModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddProfileIngressModalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAddProfileIngressModalQuery,
    GetAddProfileIngressModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetAddProfileIngressModalQuery,
    GetAddProfileIngressModalQueryVariables
  >(GetAddProfileIngressModalDocument, options);
}
export function useGetAddProfileIngressModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddProfileIngressModalQuery,
    GetAddProfileIngressModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetAddProfileIngressModalQuery,
    GetAddProfileIngressModalQueryVariables
  >(GetAddProfileIngressModalDocument, options);
}
export type GetAddProfileIngressModalQueryHookResult = ReturnType<
  typeof useGetAddProfileIngressModalQuery
>;
export type GetAddProfileIngressModalLazyQueryHookResult = ReturnType<
  typeof useGetAddProfileIngressModalLazyQuery
>;
export type GetAddProfileIngressModalQueryResult = Apollo.QueryResult<
  GetAddProfileIngressModalQuery,
  GetAddProfileIngressModalQueryVariables
>;
